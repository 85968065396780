import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';
import moment from 'moment';

"use strict";

let lang = 'pl';

//region FORM
const insertAfter = (referenceNode, newNode, options = {tag: 'div', classList : ['invalid-feedback', 'invalid-feedback-backend']}) => {
    if (typeof newNode === 'string')
    {
        const el = document.createElement(options.tag || 'p');
        el.classList.add(...(options.classList || []));
        el.innerHTML = newNode;
        newNode = el;
    }
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    return newNode;
}
const addErrorField = (referenceNode, newNode, options = {tag: 'div', classList : ['invalid-feedback', 'invalid-feedback-backend', 'mb-3']}) => {
    newNode = insertAfter(referenceNode, newNode, options);
    referenceNode.addEventListener('input', () => newNode.parentNode.removeChild(newNode));
}
const fillErrors = (form, response) => {
    console.log(response);
    //If there is general error, add it after form for debug purposes
    response.error && insertAfter(form.querySelector('[type=submit]'), response.error);

    //Support for old style response, not tested
    if( response.fields ){
        Object.keys(response.fields).forEach(key => response.fields[key].error &&
            addErrorField(form.querySelector(`[name="personal_data[${key}]"]`), response.fields[key].error)
        )
        'files' in response.fields &&
        'extraData' in response.fields &&
        ['files', 'extraData'].forEach( mainKey => response.fields[mainKey] &&
            Object.keys(response.fields[mainKey]).forEach(key => response.fields[mainKey][key].error &&
                addErrorField(form.querySelector(`[name="personal_data[${mainKey}][${key}]"]`), response.fields[mainKey][key].error)
            )
        )
        'files' in response.fields &&
        Object.keys(response.fields.files).forEach(key => response.fields.files[key].error &&
            insertAfter(form.querySelector(`[name="personal_data[files][${key}]"]`).parentNode.parentNode, response.fields.files[key].error) &&
            form.querySelector(`[name="personal_data[files][${key}]"]`).addEventListener('change', () =>
                form
                    .querySelector(`[name="personal_data[files][${key}]"]`)
                    .parentNode
                    .parentNode
                    .querySelectorAll('.invalid-feedback-backend')
                    .forEach(newNode => newNode.parentNode.removeChild(newNode))
            )
        )
    }

    //Support for new style response
    if( response.errors ){
        //var jqForm = $(form);
        for( var key in response.errors ){
            var error = response.errors[key][0];
            var fieldSelector = '[name="'+key+'"]';
            var field = form.querySelector(fieldSelector);
            if( !field ){
                var submitBtn = form.querySelector('[type=submit]');
                $(submitBtn).addClass('is-invalid');
                insertAfter(submitBtn, key + ' ' + error);
            }else{
                addErrorField(field, error)
                $(field).addClass('is-invalid');
            }
        }
    }
}

function validatenip(nip) {
    var nipWithoutDashes = nip.replace(/-/g, "");
    var reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) == false) {
        return false;
    } else {
        var digits = ("" + nipWithoutDashes).split("");
        var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;
        return (parseInt(digits[9]) == checksum);
    }
}

//endregion

$(function() {
    lang = $('html').attr('lang');


    // $('.slider').each(function () {
    //     const slidesToShow = $(this).data('slides-to-show');
    //     const arrows = $(this).data('arrows');
    //     const dots = $(this).data('dots');
    //
    //     $(this).slick({
    //         slidesToShow: slidesToShow,
    //         arrows: arrows,
    //         dots: dots,
    //         draggable: $(this).closest('#workpage').length === 0
    //     });
    // });

    $('.slider').each(function () {
        //const slidesToShow = $(this).data('slides-to-show');
        //const arrows = $(this).data('arrows');
        //const dots = $(this).data('dots');

        $(this).slick({
            //slidesToShow: slidesToShow,
            //arrows: arrows,
            //dots: dots,
            //draggable: $(this).closest('#workpage').length === 0
        });
    });

    //region application main form
    //let dziekowkaOpen = false;

    function checkKasaFiskalna(){
        var fvCheck = $('#personal_data_billTypeInvoice');
        var nrKasyFs = $('#fiskalnaFs');
        if( fvCheck.is(':checked') ){
            nrKasyFs.hide();
            nrKasyFs.find('input').removeAttr('required');
        }else{
            nrKasyFs.show();
            nrKasyFs.find('input').attr('required', true);
        }
    }

    $('#personal_data_billTypeInvoice').change( checkKasaFiskalna );
    checkKasaFiskalna();

    // let wezudzialDisabled = false;
    // $('#wezudzial').on('submit', async function (e) {
    //     console.log(e);
    //     e.preventDefault();
    //     var form = $(this);
    //     if (form[0].querySelector('[type=email]')) {
    //         form[0].querySelector('[type=email]').value = form[0].querySelector('[type=email]').value.toLowerCase();
    //     }
    //     form.find('.error, .invalid-feedback-backend').remove();
    //     form.find('input[type=submit]').removeClass('is-invalid');
    //     if (!validatenip($('#nip').val())) {
    //         $('#nip')[0].setCustomValidity('invalid');
    //     } else {
    //         $('#nip')[0].setCustomValidity('');
    //     }
    //     if (form[0].checkValidity() && !wezudzialDisabled) {
    //         wezudzialDisabled = true;
    //         $('#wezudzial').addClass('loading');
    //         const data = new FormData(form[0]);
    //         data.set('personal_data[billDate]', moment($('#dzakupu').val(), "DD.MM.YYYY").format('YYYY-MM-DD'));
    //         //data.append('recaptcha', await getReCaptcha());
    //
    //         console.log(data);
    //         $.ajax({
    //             method: "POST",
    //             url: form.attr('action'),
    //             cache: false,
    //             contentType: false,
    //             processData: false,
    //             data: data
    //         })
    //             .done(async function (msg) {
    //                 console.log('Done');
    //                 setTimeout(function() {
    //                     $('#wezudzial').removeClass('loading');
    //                 }, 1500);
    //
    //                 form.find('input[type=submit]').next().text('Przepraszamy. Wystąpił błąd. Proszę spróbować później.');
    //                 if (msg.status == 'OK') {
    //
    //                     console.log('OK');
    //
    //                     form.find('input[type=submit]').removeClass('is-invalid');
    //                     const response = await fetch(`/api/${msg.uuid}.json`);
    //                     const data = await response.json();
    //                     document.querySelectorAll('[data-application]')
    //                         .forEach(el => {
    //                             document.querySelectorAll('[data-show-if=' + el.dataset.application + ']')
    //                                 .forEach(el1 => !data[el.dataset.application] ?
    //                                     el1.classList.add('d-none') :
    //                                     el1.classList.remove('d-none'))
    //                             el.innerHTML = data[el.dataset.application]
    //                         });
    //                     document.querySelectorAll('[data-application-pdf]')
    //                         .forEach(el => el.setAttribute('href', data.pdf));
    //                     $('#wezudzial').slideUp();
    //                     $('#nrkasypar').removeClass('d-none');
    //
    //                     //var axel = Math.random() + "";
    //                     //var a = axel * 10000000000000;
    //                     //$('body').prepend('<img src="https://ad.doubleclick.net/ddm/activity/src=9054845;type=invmedia;cat=2022_0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?" width="1" height="1" alt=""/>');
    //
    //                     if (data.won === true) {
    //                         $('.prize1, .prize2, .prize3, .prize4').addClass('d-none');
    //                         if (msg.prizeId) {
    //                             if (msg.prizeName=='Glosnik') { //głośnik jbl
    //                                 $('#winnerPrizeName').text('głośnika JBL!');
    //                                 $('.prize1').removeClass('d-none');
    //                             } else if (msg.prizeName=='Plecak') { //plecak
    //                                 $('#winnerPrizeName').text('plecaka wraz z zapasem produktów 7Days!');
    //                                 $('.prize2').removeClass('d-none');
    //                             } else if (msg.prizeName=='Sluchawki') { //słuchawki bt
    //                                 $('#winnerPrizeName').text('słuchawek JBL!');
    //                                 $('.prize3').removeClass('d-none');
    //                             } else if (msg.prizeName=='Smartwatch') { //zegarek
    //                                 $('#winnerPrizeName').text('zegarka Samsung Galaxy!');
    //                                 $('.prize4').removeClass('d-none');
    //                             }
    //                         }
    //                         $('#wezudzialAfterWinner').addClass('active');
    //                         dziekowkaOpen = true;
    //                     } else {
    //                         $('#wezudzialAfterLooser').addClass('active');
    //                         dziekowkaOpen = true;
    //                     }
    //                     wezudzialDisabled = false;
    //                 } else {
    //                     console.log('error');
    //                     wezudzialDisabled = false;
    //                     form.find('input[type=submit]').next().text('Wystąpił błąd. Proszę sprawdzić wypełniony formularz.');
    //                     form.find('input[type=submit]').addClass('is-invalid');
    //                     if (msg.fields && msg.fields.email && msg.fields.email.error) {
    //                         form.find('input[type=submit]').next().text(msg.fields.email.error);
    //                         delete msg.fields.email.error;
    //                     }
    //                     fillErrors(form[0], msg);
    //                 }
    //             })
    //             .fail(function (jqXHR, textStatus) {
    //                 console.log( 'fail' );
    //                 wezudzialDisabled = false;
    //                 $('#wezudzial').removeClass('loading');
    //                 form.find('input[type=submit]').next().text('Przepraszamy. Wystąpił błąd. Proszę spróbować później.');
    //                 form.find('input[type=submit]').addClass('is-invalid');
    //                 fillErrors(form[0], jqXHR.responseJSON);
    //             });
    //     }
    //     return false;
    // });


    //endregion

    //region scrolllinks
    $('.scroll-link[href^="#"]').on('click', function (e) {
        e.preventDefault();
        var dis = $(this);
        if(dziekowkaOpen) {
            $('#wezudzialAfterWinner, #wezudzialAfterLooser').removeClass('active');
            $('#wezudzial').trigger("reset");
            $('#wezudzial').removeClass('was-validated');
            $('#wezudzial').slideDown(400, function () {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(dis.attr('href')).offset().top - $('#navbar-main').height() + 1
                }, 600);
            });
            dziekowkaOpen = false;
        } else {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(dis.attr('href')).offset().top - $('#navbar-main').height() + 1
            }, 600);
        }
        //window.location.hash = $(this).attr('href');
    });
    $('.scroll-link[href^="/"]').on('click', function (e) {
        window.location.href = $(this).attr('href');
    });
    //endregion

    //region Share
    // function copyToClipboard(text) {
    //     if (navigator.clipboard) { // If nagivator.clipboard is supported
    //         navigator.clipboard.writeText(text).then(function() {
    //             return true;
    //         }, function(err) {
    //             console.error('Could not copy text: ', err);
    //         });
    //     } else if (window.clipboardData) { // For Internet Explorer
    //         window.clipboardData.setData("Text", text);
    //         if( window.clipboardData.getData() == text ) return true;
    //         console.error('Could not copy text.');
    //         return false;
    //     } else { // Fallback for older browsers
    //         return copyTextToClipboard(text);
    //         // console.log('fallback copy');
    //         // var dummy = document.createElement("textarea");
    //         // document.body.appendChild(dummy);
    //         // dummy.value = text;
    //         // dummy.select();
    //         // document.execCommand("copy");
    //         // document.body.removeChild(dummy);
    //         // return true;
    //     }
    //
    // }
    //
    // function copyTextToClipboard(text) {
    //     if (!navigator.clipboard) {
    //         fallbackCopyTextToClipboard(text);
    //         return true;
    //     }
    //     navigator.clipboard.writeText(text).then(function() {
    //         //console.log('Async: Copying to clipboard was successful!');
    //         return true;
    //     }, function(err) {
    //         console.error('Async: Could not copy text: ', err);
    //         return false;
    //     });
    // }
    // function fallbackCopyTextToClipboard(text) {
    //     var adrinput = $('.copy_www input').first();
    //
    //     adrinput.focus();
    //     adrinput.select();
    //     var successful = false;
    //     try {
    //         successful = document.execCommand('copy');
    //         //var msg = successful ? 'successful' : 'unsuccessful';
    //         //console.log('Fallback: Copying text command was ' + msg);
    //         adrinput.unselect();
    //     } catch (err) {
    //         console.error('Fallback: Oops, unable to copy', err);
    //         //successful = false;
    //     }
    //     return successful;
    // }

    $('.copy_www .btn').click(function () {

        var btn = $(this);
        var cw = btn.closest('.copy_www');
        var input = cw.find('input:first');
        var text = input.val();

        if (navigator.clipboard) { // If nagivator.clipboard is supported
            navigator.clipboard.writeText(text).then(function() {
                btn.text('OK');
            }, function(err) {
                input.val( err );
                btn.text(':((');
            });
        } else if (window.clipboardData) { // For Internet Explorer
            window.clipboardData.setData("Text", text);
            if( window.clipboardData.getData() == text ){
                btn.text('OK');
            }
                btn.text(':(');
        }else{
            input.select();
            document.execCommand('copy');
            btn.text('OK');
        }

    });

    $('.copy_www input').click(function () {
        $(this).select();
        document.execCommand('copy');
    });


    // $('#shareButton').click(function () {
    //     $('#shareModal').show();
    // })

    //endregion Share

    //region contact
    // DEPRECATED - DO NOT USE IN NEWER PROJECTS!
    $("#wiadomosc").keyup(function () {
        $("#counterVal").text($(this).val().length);
    });

    // let contactFormDisabled = false;
    // $('#contactForm').on('submit', async function (e) {
    //     e.preventDefault();
    //     var form = $(this);
    //     form.addClass('sending');
    //     if (form[0].querySelector('[type=email]')) {
    //         form[0].querySelector('[type=email]').value = form[0].querySelector('[type=email]').value.toLowerCase();
    //     }
    //     form.find('input[type=submit]').removeClass('is-invalid');
    //     form.find('.error, .invalid-feedback-backend').remove();
    //     if (form[0].checkValidity() && !contactFormDisabled) {
    //         var contactFormDisabled = true;
    //         const data = new FormData(form[0]);
    //         //data.append('recaptcha', await getReCaptcha())
    //         $.ajax({
    //             method: "POST",
    //             url: form.attr('action'),
    //             cache: false,
    //             contentType: false,
    //             processData: false,
    //             data: data
    //         })
    //             .done(async function (msg) {
    //                 form.find('input[type=submit]').next().text('Przepraszamy. Wystąpił błąd. Proszę spróbować później.');
    //                 if (msg.status == 'OK') {
    //                     form.find('input[type=submit]').removeClass('is-invalid');
    //                     const response = await fetch(`/api/${msg.uuid}.json`);
    //                     const data = await response.json();
    //                     document.querySelectorAll('[data-contact]')
    //                         .forEach(el => {
    //                             el.innerHTML = data[el.dataset.contact]
    //                         });
    //                     document.querySelectorAll('[data-contact-pdf]')
    //                         .forEach(el => el.setAttribute('href', data.pdf));
    //                     $('#contactForm').slideUp();
    //                     $('#contactFormAfter').slideDown(400, function () {
    //                         // $([document.documentElement, document.body]).animate({
    //                         //     scrollTop: $('#kontakt').offset().top - $('#navbar-main').height() + 1
    //                         // }, 600);
    //                     });
    //                     contactFormDisabled = false;
    //                 } else {
    //                     contactFormDisabled = false;
    //                     form.find('input[type=submit]').next().text('Wystąpił błąd. Proszę sprawdzić wypełniony formularz');
    //                     form.find('input[type=submit]').addClass('is-invalid');
    //                     fillErrors(form[0], msg);
    //                 }
    //                 form.removeClass('sending');
    //             })
    //             .fail(function (jqXHR, textStatus) {
    //                 contactFormDisabled = false;
    //                 form.find('input[type=submit]').next().text('Przepraszamy. Wystąpił błąd. Proszę spróbować później.');
    //                 form.find('input[type=submit]').addClass('is-invalid');
    //                 fillErrors(form[0], jqXHR.responseJSON);
    //                 form.removeClass('sending');
    //             });
    //     }
    //     return false;
    // });

    $('.reset-cform').on('click', function (e) {
        e.preventDefault();
        $('#contactFormAfter').slideUp();
        $('#contactForm').trigger("reset");
        $('#contactForm').removeClass('was-validated');
        $('#contactForm').slideDown(400, function () {
            // $([document.documentElement, document.body]).animate({
            //     scrollTop: $('#contactForm').offset().top - $('#navbar-main').height() + 1
            // }, 600);
        });
    });

    //endregion contact


    var captcha = $(".grecaptcha-badge").parent();
    captcha.addClass('captchaarea');
    $('#footer').append(captcha);

});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
