import 'bootstrap5';

// keep these scss files as separate modules for faster builds (import here instead in the global scss)
import './styles/bootstrap.scss';
import './styles/app.scss';

import './js/forms/form';
import './js/forms/registered-data';
import './js/app';
import './js/workflow-form';
import './js/application';
import './js/main';
import './js/vote';
import './js/winners';
import './js/layout/navigation';
import './js/input-file-image-preview';
import './js/calendar';