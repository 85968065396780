import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import './calendar-pl.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

const calendar = $('#datepicker-winners').datepicker({
	clearBtn: false,
	language: "pl",
	maxViewMode: 0,
	startDate: new Date($('#datepicker-winners').attr('data-start')),
	endDate: new Date($('#datepicker-winners').attr('data-end')),
	defaultViewDate: {
		year: 2024,
		month: 8,
		day: 1
	},
});

calendar.on('changeDate', function (e) {

	//$('section#winners ul[data-id=city]').empty();
	//$('section#winners ul[data-id=technic]').empty();
	//$('section#winners ul[data-id=friends]').empty();
	var winnersLists = $('.with-winner');

	let date = new Date(e.date);
	let formattedDate = date.toLocaleDateString('pl-PL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).replace(/\./g, '-');
	formattedDate = formattedDate.split('-').reverse().join('-');
	winnersLists.addClass('loading');

	console.log( winnersLists );
	$('.current-date').text(formattedDate = formattedDate.split('-').reverse().join('.'));
	winnersLists.html('');

	$.ajax({
		url: '/winners',
		type: 'GET',
		data: {
			date: formattedDate
		},
		success: function (data) {
			console.log(data);
            if (data.winners.length > 0) {
                let winners = '';
                data.winners.forEach(function (winner) {
                    winners += `<tr><td>${winner.name} </td><td>${winner.id} </td><tr/>`;
                });
				winnersLists.html(winners);
            }
		},
		error: function (data) {
			console.log(data);
			winnersLists.html('<br/><br/>Wystąpił błąd, spróbój jeszcze raz.');
		},
	}).always( function (){ winnersLists.removeClass('loading');} )
	;
});

// $('.datepicker').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// });

// $('.datepicker-birthday').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// 	maxViewMode: 3,
// 	startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 120)),
// 	endDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
// 	defaultViewDate: {
// 		year: new Date().getFullYear() - 18,
// 		month: 0,
// 		day: 1
// 	},
// });
//
// $('.datepicker-purchase').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// 	maxViewMode: 0,
// 	startDate: new Date(2023, 4, 2),
// 	endDate: new Date(2023, 5, 26),
// 	defaultViewDate: {
// 		year: 2023,
// 		month: 0,
// 		day: 2
// 	},
// });