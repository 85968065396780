import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.application-form').on('submit-success', function(event, data) {
    $('.ladningpage').addClass('d-none');//Hide main page, only modal stays, to not duble scrollbars
    // $('.page-game').removeClass('d-none');
    $('.result').addClass('d-none');//Hide other modals for sure

    //Showing correct modal
    var prizemodal = $(`.result-${data.prize ? 'success' : 'failed'}`);
    prizemodal.removeClass('d-none').show();

    //if (data.prize) {
        // prizemodal.find('.result-prize-name').html(data.prize.name);
        //prizemodal.find('.result-form-link').attr('href', data.formUrl);
    //}
    setRegisteredData(data, '#modals');
});

//Return button on winner/looser modal
$('.return-from-modal').on('click', function() {
    $('.ladningpage').removeClass('d-none'); //Returning page
    $('.result').addClass('d-none');
});

// $('.result-try-again').on('click', function() {
//     $('.page').addClass('d-none');
//     $('.page-form').removeClass('d-none');
// });

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none').css('display', 'block');
    $('.after-fill').removeClass('d-none');

    setRegisteredData(data, '#modal-form-winner');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#contact');
    //
    // // page swap
    // $('.page').addClass('d-none');
    // $('.page-thank-you').removeClass('d-none').css('display', 'block');

    $('.contact-form').slideUp();
    $('#contactFormAfter').slideDown(400, function () {
        // $([document.documentElement, document.body]).animate({
        //     scrollTop: $('#kontakt').offset().top - $('#navbar-main').height() + 1
        // }, 600);
    });

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});